/* Pay.css */
.pay-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff; /* White background */
  }
  
  .pay-content {
    text-align: center;
    border-radius: 8px;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    background-color: #6b8bcf; /* Keeping the blue for the content box */
  }
  
  .pay-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ffffff; /* White text for contrast */
  }
  
  .pay-description {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #ffffff; /* White text for contrast */
  }
  
  .pay-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #4a6fa5;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pay-button:hover {
    background-color: #e0e0e0; /* Light grey for hover effect */
  }
  